<template>
    <div class="d-flex flex-column justify-center align-center fill-height">
        <v-card max-width="500" v-if="isRegisteringOrAccepting === false">
            <v-card-title> <v-icon class="mr-1">mail</v-icon>Invitation</v-card-title>
            <v-card-text class="black--text" v-if="validInvitation !== false && recommendedAction !== 'None'">
                You have received invitation(s) to Nuvolos. Please review the grants and continue if you wish to accept.
                <div class="mt-5 d-flex flex-column">
                    <span class="primary--text caption text-uppercase">review grants</span>
                    <v-divider></v-divider>
                </div>
                <v-expansion-panels hover class="mt-3 flat-expansion-panel">
                    <v-expansion-panel class="elevation-0">
                        <v-expansion-panel-header>{{ roles.length }} grants</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container grid-list-md fluid>
                                <div class="d-flex flex-column flex-wrap">
                                    <div v-for="(r, index) in roles" :key="index" class="mb-1">
                                        <v-card tile outlined>
                                            <v-card-title>
                                                <span class="subtitle-1 primary--text">INVITATION {{ index + 1 }}</span>
                                            </v-card-title>
                                            <v-card-text class="text--primary">
                                                <div v-for="(value, name) in user_pretty(r)" :key="name">
                                                    <span class="text-truncate" style="display:block">
                                                        <span class="font-weight-bold primary--text">{{ name }}</span>
                                                        : {{ value }}
                                                    </span>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </div>
                                </div>
                            </v-container>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div v-if="emailIsRegistered !== null">
                    <div v-if="!isLoggedIn && !emailIsRegistered">
                        <v-divider class="my-2" />
                        <ask-about-account>
                            <div class="my-2 font-weight-bold">Account creation options</div>
                            <institution-login>
                                <div v-if="createNewAccount">
                                    <div v-if="recommendedAction === recommendedActions.SIGN_UP_NEW_USER">
                                        <the-email-signup-option :componentSpecificProps="{ userEmail: email }" />
                                    </div>
                                    <div v-if="recommendedAction === recommendedActions.SIGN_UP_WITH_EXISTING_MIDPOINT_ACCOUNT">
                                        <the-upgrade-to-nuvolos-option :componentSpecificProps="{ userEmail: email }" />
                                    </div>
                                </div>
                                <div v-else>
                                    <v-btn @click="setCreateNewAccount" text block>E-mail address</v-btn>
                                </div>
                            </institution-login>
                        </ask-about-account>
                    </div>
                    <div
                        wrap
                        justify-center
                        align-center
                        class="text-center caption"
                        v-else-if="nuvolosEmail.toLowerCase() === email.toLowerCase() || (isLoggedIn && !emailIsRegistered)"
                    >
                        You are logged in and can continue to accept the invitation.
                        <v-btn
                            class="mt-4 mb-7 accept-with-current-user"
                            color="secondary"
                            :to="{ name: 'invitation-check-user', params: { invid: $route.params.invid } }"
                            block
                            >Continue</v-btn
                        >
                    </div>
                    <div v-else-if="isLoggedIn" class="mt-2">
                        The invitation seems to correspond to a different e-mail. Please
                        <v-btn color="secondary" @click="$auth.logout" block class="my-4">log out</v-btn>
                        from the current account and then open again the invitation.
                    </div>
                    <div v-else class="mt-2">
                        The e-mail for this invitation is already registered. After clicking on the button below, use the following e-mail to sign in:
                        <div class="text-center my-4 font-weight-bold">
                            {{ email }}
                        </div>
                        <v-btn color="secondary" block @click="loginAndReturn">Next</v-btn>
                    </div>
                </div>
                <div v-else>
                    Checking if e-mail is registered already...
                </div>
            </v-card-text>
            <v-card-text v-else
                >We are sorry but this invitation is no longer valid.
                <div class="d-flex justify-center mt-2">
                    <v-btn :to="{ name: 'login' }" color="secondary" text>Sign in to Nuvolos</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { defaultAccountType } from '@/utils'
import { mapState } from 'vuex'
import store from 'store'
const TheUpgradeToNuvolosOption = () => import('../components/TheUpgradeToNuvolosOption')
const TheEmailSignupOption = () => import('../components/TheEmailSignupOption')
const AskAboutAccount = () => import('@/modules/invitations/components/AskAboutAccount.vue')
const InstitutionLogin = () => import('@/modules/invitations/components/InstitutionLogin.vue')

export default {
    name: 'invitation-review',
    components: {
        TheUpgradeToNuvolosOption,
        TheEmailSignupOption,
        AskAboutAccount,
        InstitutionLogin
    },
    data() {
        return {
            isLoggedIn: this.$auth.isAuthenticated(),
            validInvitation: null,
            roles: '',
            email: '',
            recommendedAction: '',
            emailType: null,
            invitationStatus: '',
            invitationOptionsPanel: 0,
            recommendedActions: {
                LOGIN_WITH_SWITCH: 'login/switch',
                LOGIN_WITH_NUVOLOS_ACCOUNT: 'login/password',
                SIGN_UP_WITH_EXISTING_MIDPOINT_ACCOUNT: 'register/midpoint',
                SIGN_UP_NEW_USER: 'register'
            },
            loggedInEmail: this.$auth.isAuthenticated() ? this.$auth.profile.email : '',
            createNewAccount: false,
            statusOptions: {
                REGISTERING_ACCOUNT: 'REGISTERING',
                ACCEPTING_INVITATION: 'ACCEPTING'
            },
            emailIsRegistered: null
        }
    },
    mounted() {
        this.$axios
            .get(`/invitations/describe/${this.$route.params.invid}`)
            .then(response => {
                this.$data.roles = response.data.roles
                this.$data.validInvitation = response.data.valid === 'True'
                this.$data.email = response.data.requestee_email
                this.$axios.post('/users/login_methods', { email: this.$data.email }).then(r => {
                    if (r.data && r.data.methods && r.data.methods.length) {
                        this.$data.emailIsRegistered = true
                    } else {
                        this.$data.emailIsRegistered = false
                    }
                })
                this.$data.emailType = this.getDefaultAccountType(response.data.requestee_email)
                this.$data.recommendedAction = response.data.recommended_action
                this.$data.invitationStatus = response.data.status_name
                if (response.data.status_name === this.$data.statusOptions.ACCEPTING_INVITATION) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Accepting invitation',
                        dialogText: 'This invitation has already been accepted, please continue to wait and reload the page after 1-2 minutes',
                        dialogAction: 'reload'
                    })
                } else if (response.data.status_name === this.$data.statusOptions.REGISTERING_ACCOUNT) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Registering account',
                        dialogText: 'Registration for this invitation has already been started, please continue to wait and reload the page after 1-2 minutes.',
                        dialogAction: 'reload'
                    })
                }
            })
            .catch(e => {
                console.log(e)
                this.$data.validInvitation = false
            })
    },
    computed: {
        ...mapState(['userInfo']),
        nuvolosEmail() {
            if (this.userInfo && this.userInfo.email) {
                return this.userInfo.email
            } else {
                return ''
            }
        },
        isRegisteringOrAccepting() {
            if (
                (this.$data.invitationStatus && this.$data.invitationStatus === this.$data.statusOptions.ACCEPTING_INVITATION) ||
                this.$data.invitationStatus === this.$data.statusOptions.REGISTERING_ACCOUNT
            ) {
                return true
            }
            return false
        }
    },
    methods: {
        getDefaultAccountType(email) {
            return defaultAccountType(email)
        },
        user_pretty(r) {
            var prettyInvite = {}
            if (r.Name) {
                prettyInvite.Role = r.Name
            }
            if (r.Instance) {
                prettyInvite.Instance = r.Instance
            }
            if (r.Space) {
                prettyInvite.Space = r.Space
            }
            if (r.Organization) {
                prettyInvite.Organization = r.Organization
            }
            return prettyInvite
        },
        handleLoginEvent(e) {
            this.$data.isLoggedIn = e.loggedIn
            this.$data.loggedInEmail = this.$auth.profile.email
        },
        setCreateNewAccount() {
            this.$data.createNewAccount = true
        },
        loginAndReturn() {
            store.set('nvLastEmail', this.$data.email)
            store.set('nvTarget', location.pathname)
            this.$router.push({ name: 'login' })
        }
    }
}
</script>
